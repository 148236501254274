.mpc-6-managed-by {
  font-weight: 600;
}
.mpc-6-iotampc {
  color: var(--mpc-6-dark-tanglepay);
}
.mpc-6-iotampc-all-right,
.mpc-6-managed-by-iotampc-container {
  position: absolute;
  left: 0;
  line-height: 24px;
}
.mpc-6-managed-by-iotampc-container {
  top: 48.mpc-6-66px;
}
.mpc-6-iotampc-all-right {
  top: 147px;
}
.mpc-6-svg-icon {
  position: relative;
  width: 16px;
  height: 16px;
  flex-shrink: 0;
  overflow: hidden;
}
.mpc-6-twitter {
  border-radius: var(--mpc-6-br-smi);
  background-color: var(--mpc-6-light-t1-day);
  display: flex;
  flex-direction: row;
  padding: var(--mpc-6-padding-8xs);
  align-items: flex-start;
  justify-content: flex-start;
}
.mpc-6-div1,
.mpc-6-div2 {
  position: absolute;
}
.mpc-6-div2 {
  top: 97px;
  left: 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 10px;
}
.mpc-6-div1 {
  height: calc(100% - 177px);
  width: calc(100% - 1653px);
  top: 88px;
  right: 1223px;
  bottom: 89px;
  left: 430px;
  font-size: var(--mpc-6-font1-size);
  color: var(--mpc-6-light-t1-day);
}
.mpc-6-contact-us {
  position: relative;
  line-height: 18px;
}
.mpc-6-ul,
.mpc-6-ul1 {
  position: absolute;
  top: 137px;
  display: flex;
  flex-direction: column;
  padding: var(--mpc-6-padding-8xs) 1.015625px 6.296875px 0;
  box-sizing: border-box;
  justify-content: flex-start;
}
.mpc-6-ul {
  left: 1213px;
  height: 122px;
  align-items: flex-start;
  gap: 16px;
}
.mpc-6-ul1 {
  left: 1451px;
  height: 19px;
  align-items: center;
}
.mpc-6-keyboard-arrow-up-icon,
.mpc-6-vector-icon {
  position: absolute;
  top: 146px;
  left: 1468px;
  width: 24px;
  height: 10px;
}
.mpc-6-vector-icon {
  height: 5%;
  width: 1.04%;
  top: 35.25%;
  right: 24.95%;
  bottom: 59.75%;
  left: 74.01%;
  max-width: 100%;
  overflow: hidden;
  max-height: 100%;
  opacity: 0.3;
}
.mpc-6-div {
  position: relative;
  background-color: var(--mpc-6-dark-t2);
  width: 100%;
  height: 400px;
  overflow: hidden;
  text-align: left;
  font-size: var(--mpc-6-font-size);
  color: var(--mpc-6-light-t2-day);
  font-family: var(--mpc-6-font);
}