.mpc-6-managed-by {
  font-weight: 600;
}

.mpc-6-iotampc {
  color: var(--mpc-6-dark-tanglepay);
}

.mpc-6-iotampc-all-right, .mpc-6-managed-by-iotampc-container {
  line-height: 1.25vw;
  position: absolute;
  left: 0;
}

.mpc-6-managed-by-iotampc-container {
  top: 48. mpc-6-3.4375vw;
}

.mpc-6-iotampc-all-right {
  top: 7.65625vw;
}

.mpc-6-svg-icon {
  width: .83333vw;
  height: .83333vw;
  flex-shrink: 0;
  position: relative;
  overflow: hidden;
}

.mpc-6-twitter {
  border-radius: var(--mpc-6-br-smi);
  background-color: var(--mpc-6-light-t1-day);
  padding: var(--mpc-6-padding-8xs);
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  display: flex;
}

.mpc-6-div1, .mpc-6-div2 {
  position: absolute;
}

.mpc-6-div2 {
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: .52083vw;
  display: flex;
  top: 5.05208vw;
  left: 0;
}

.mpc-6-div1 {
  height: calc(100% - 9.21875vw);
  width: calc(100% - 86.0938vw);
  font-size: var(--mpc-6-font1-size);
  color: var(--mpc-6-light-t1-day);
  inset: 4.58333vw 63.6979vw 4.63542vw 22.3958vw;
}

.mpc-6-contact-us {
  line-height: .9375vw;
  position: relative;
}

.mpc-6-ul, .mpc-6-ul1 {
  padding: var(--mpc-6-padding-8xs) .0529vw .32796vw 0;
  box-sizing: border-box;
  flex-direction: column;
  justify-content: flex-start;
  display: flex;
  position: absolute;
  top: 7.13542vw;
}

.mpc-6-ul {
  height: 6.35417vw;
  align-items: flex-start;
  gap: .83333vw;
  left: 63.1771vw;
}

.mpc-6-ul1 {
  height: .98958vw;
  align-items: center;
  left: 75.5729vw;
}

.mpc-6-keyboard-arrow-up-icon, .mpc-6-vector-icon {
  width: 1.25vw;
  height: .52083vw;
  position: absolute;
  top: 7.60417vw;
  left: 76.4583vw;
}

.mpc-6-vector-icon {
  height: 5%;
  width: 1.04%;
  max-width: 100%;
  max-height: 100%;
  opacity: .3;
  inset: 35.25% 24.95% 59.75% 74.01%;
  overflow: hidden;
}

.mpc-6-div {
  background-color: var(--mpc-6-dark-t2);
  width: 100%;
  height: 20.8333vw;
  text-align: left;
  font-size: var(--mpc-6-font-size);
  color: var(--mpc-6-light-t2-day);
  font-family: var(--mpc-6-font);
  position: relative;
  overflow: hidden;
}

/*# sourceMappingURL=mpc.82f54225.css.map */
